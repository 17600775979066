<template>
  <div class='container'>
    <div class="content">
      <div class="box">
         <div class="box-title">学生信息</div>
         <div class="message">
           <p><b style="font-size: 4.8vw;margin-right: 1.3333vw;">{{vo.trueName}}</b> <span style="background: #EFF9F8;padding: .8vw 2.1333vw;color:#10CA9B ;font-size: 2.9333vw;border-radius: .8vw;">{{ gradeList[vo.grade] }}{{ vo.studyClass }}</span></p>
           <span>校区：{{ vo.area?vo.area:'未填写' }}</span>
           <span>学籍号：25FSD651526369854</span>
           <span>测评时间：2023-07-26 10:42:37</span>
         </div>
       </div>
       <div class="box">
         <div class="box-title">上报人信息</div>
         <div class="message">
           <span>姓名：{{ helper&&helper.trueName }}</span>
           <span>岗位：{{ helper&&helper.job }}</span>
           <span>手机号：{{ helper&&helper.phone }}</span>
         </div>
       </div>
       <div class="box">
         <div class="box-title">上报维度和内容</div>
         <template v-if="answers&&answers.length > 0">
           <div class="student-info" v-for="(answers,index) in answers" :key="answers.tagName">
             <div class="info-title">
               {{ answers.tagName }}
             </div>
             <div class="student-item" v-for="item in answers.items" :key="item.id">
               <div :class="['circle',item.a==0?'white':'']"></div>
               <p>{{ item.title }}</p>
             </div>
           </div>
         </template>
        <van-empty description="暂无数据" v-else></van-empty>
      </div>
    </div>
    <div class="bottom" >
      <button @click="$router.push('index')">返回预警列表</button>
    </div>
  </div>
</template>

<script>
import {viewDetail} from '@/api/warning'
export default {
  data(){
    return{
      helper:{},
      vo:{},
      answers:[],
      gradeList: ['一年级', '二年级', '三年级', '四年级', '五年级', '六年级', '初一', '初二', '初三', '高一', '高二', '高三']
    }
  },
  created(){
    this.getDetail()
  },
  methods:{
    async getDetail(){
      const {id}=this.$route.query
      try {
          // 观察记录详情
          const res = await viewDetail({id})
          this.helper = res.data.helper
          this.vo = res.data.vo
          if (res.data.vo.answers) {
            // 处理后台返回数据
            res.data.vo.answers.forEach(item => {
              if (item.items[0].items) {
                this.answers.push(item.items[0])
              }
            })
          }
          // 第一次返回的数据格式不用做处理
          if (this.answers.length === 0) {
            this.answers = res.data.vo.answers
          }
        if (this.answers) {
          // 过滤没有选中的，循环其中有一项等于1即为选中
         this.answers = this.answers.filter(item => {
            if (item.items) {
              let state = false
                for (let i = 0; i < item.items.length; i++) {
                  const ele = item.items[i]
                  if (ele.a === 1) {
                    state = true
                    break
                  } else {
                    state = false
                  }
                }
              return state
            }
          })
        }
      } catch (error) {
        console.log(error)
        this.$message.warning('找不到记录')
        this.$router.go(-1)
      }
    }
  }
}
</script>
<style lang='less' scoped>
.container{
  padding:5.8667vw 0 16.5333vw 0;
  min-height: 100%;
  background-color: #fff;
  .content{
    margin: 0 3.2vw;
    padding: 5.3333vw 4vw;
    box-shadow: 0vw 0vw 2.6667vw 0vw rgba(187,187,187,0.15);
    border-radius: 2.6667vw;
  }
  .box{
    margin-bottom: 2.6667vw;
    .box-title{
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 4vw;
      font-size: 4vw;
      font-weight: 550;
      color: #FB6D3F;
      &::before{
        content: '';
        position: absolute;
        left: 0;
        width: .8vw;
        height: 4vw;
        border-radius: .8vw;
        background-color: #FB6D3F;
      }
    }
    .message{
      margin-top: 6.4vw;
      font-size: 3.4667vw;
      display: flex;
      flex-direction: column;
      p{
        margin-bottom: 2.6667vw;
      }
      span{
        color: rgba(121, 121, 121, 1);
        margin-bottom: 2.6667vw;
      }
    }
    .student-info{
      margin-top: 4vw;
      // padding: 0 4vw;
      .info-title{
        width: 100%;
        height: 11.2vw;
        line-height: 11.2vw;
        text-align: center;
        color: #fff;
        font-size: 3.7333vw;
        background-color: #5EDBC9;
      }
      .student-item{
        padding: 2.6667vw 4vw;
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 11.2vw;
        background-color: #fff;
        &:nth-of-type(2n){
          background-color:#F8F8F8 ;
        }
        p{
          flex: 1;
          color: #666;
        }
        .circle{
          position: relative;
          margin-right: 4vw;
          width: 3.2vw;
          height: 3.2vw;
          border-radius: 50%;
          background-color: #fff;
          border: .5333vw solid #5EDBC9;
          box-sizing: border-box;
          /* &::after{
            position: absolute;
            content: '';
            top: 50%;
            left: 50%;
            width: 1.6vw;
            height: 1.6vw;
            border-radius: 50%;
            background-color: #c0c4cc;
            transform: translate3d(-50%,-50%,0);
          } */
        }
        .circle.white{
          border: .2667vw solid #E7E7E7;
          background-color: #fff;
          &::after{
            display: none;
          }
        }
      }
    }
  }
  .bottom{
     position: fixed;
     bottom: 0;
     width: 100%;
     height: 16vw;
     padding: 1.0667vw 4vw;
     button{
       height: 13.8667vw;
       width: 100%;
       line-height: 13.8667vw;
       text-align: center;
       font-size: 4.2667vw;
       color: #fff;
       border-radius: 13.3333vw;
       background-color: #5EDBC9;
     }
  }
}
</style>